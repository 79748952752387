import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
import 'vant/lib/index.css'
import '@/assets/styles/common.scss'
import { initVueSentry } from '@dp/sentry-client'
const app = createApp(App)
console.log('update11111111')
if (SENTRY_ENABLE) {
  initVueSentry({
    app,
    router,
    dsn: 'https://b620b5b809d245bba3d41179c538e87b@sentry.deepocean.tech/23',
    version: APP_VERSION,
  })
}

app.use(router).mount('#app')
